<template>
  <div class="mobile-menu-icon" @click="toggleMenu()" :class="{ active: menuActive }">
    <span class="material-icons" v-if="!menuActive">menu</span>
    <span class="material-icons" v-if="menuActive">close</span>
  </div>
  <div id="nav" class="text-start" :class="{ active: menuActive }" @click="toggleMenu()">
    <h4 style="">Emmanuel<br />Ministries<br />International</h4>


    <router-link v-if="!me.profile" to="/">
      <span class="material-icons">home</span>
      Home
    </router-link>
    <!-- <router-link to="/about">
      <span class="material-icons">maps_home_work</span>
      About
    </router-link> -->
    <router-link v-if="me.profile" to="/feed">
      <span class="material-icons m-1">stream</span>
      {{ $t("message.feed") }}
    </router-link>
    <router-link v-if="me.profile" to="/search">
      <span class="material-icons m-1">search</span>
      {{ $t("message.search") }}
    </router-link>
    <router-link v-if="me.profile" to="/invite">
      <span class="material-icons m-1">person_add_alt</span>
      {{ $t("message.invite") }}
    </router-link>
    <router-link v-if="me.profile" to="/groups">
      <span class="material-icons m-1">groups</span>
      {{ $t("message.groups") }}
    </router-link>
    <router-link v-if="me.profile" to="/course">
      <span class="material-icons m-1">subscriptions</span>
      {{ $t("message.courses") }}
    </router-link>
   <!--  <router-link v-if="me.profile" to="/songs">
      <span class="material-icons m-1">music_note</span>
      {{ $t("message.songs") }}
    </router-link>-->
    <router-link v-if="me.profile" to="/giving">
      <span class="material-icons m-1">volunteer_activism</span>
      {{ $t("message.giving") }}
    </router-link>
    <router-link v-if="me.profile" to="/livestream">
      <span class="material-icons m-1">live_tv</span>
      {{ $t("message.livestream") }}
    </router-link>
    <router-link v-if="me.profile" to="/events">
      <span class="material-icons m-1">event</span>
      {{ $t("message.events") }}
    </router-link>
    <router-link v-if="me.profile" to="/logout">
      <span class="material-icons m-1">logout</span>
      {{ $t("message.logout") }}
    </router-link>
    <p>
      <span @click="$i18n.locale = 'en'">English</span> //

      <span @click="$i18n.locale = 'es'">Spanish</span>
    </p>
    <div class="currentUser m-1" v-if="me.profile" style="font-size: 1.1rem;" >
      <UserName :id="UserStore.me._id" :key="UserStore.me._id" :showImgCircle="true" />
    </div>
  </div>
  <MobileMenu />
  <div class="main-wrapper">
    <router-view v-slot="{ Component }">
      <transition name="scale" mode="out-in">
        <component v-if="initialLoad" :is="Component" :user="me" :key="$route.path" />
      </transition>
    </router-view>
  </div>
  <RightPanel :user="me" id="rightPanel" v-if="me.profile" />
</template>

<script>
import API from "./API.js";
import RightPanel from "./components/UserAndMessages.vue";
import UserName from "./components/UserName.vue";
import { UserStore } from "./UserStore.js";
import MobileMenu from "./components/MobileMenu.vue";

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const publicVapidKey =
  "BMMUVKDgBg7iA7fLmxJy4EgsywqVs9dNxzzzYjomr4kptiJEzzLQN9zDl6FSbfL_o4rxM-Iiy97wyHvde3cH4h8";

async function triggerPushNotification() {
  if ("serviceWorker" in navigator) {
    const register = await navigator.serviceWorker.register("/sw.js", {
      scope: "/",
    });
    const subscription = await register.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
    });
    API.subscribe(subscription).then(console.log);
  } else {
    console.error("Service workers are not supported in this browser");
  }
}

function askNotificationPermission() {
  function handlePermission() {
    if (Notification.permission === "granted") {
      triggerPushNotification();
    }
  }

  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    console.log("This browser does not support notifications.");
  } else {
    if (Notification.permission !== "granted") {
      Notification.requestPermission(function (permission) {
        handlePermission(permission);
      });
    }
  }
}

export default {
  data() {
    return {
      me: {},
      menuActive: false,
      initialLoad: false,
      UserStore,
      isMobile: navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i),
    };
  },
  components: {
    RightPanel,
    UserName,
    MobileMenu,
  },
  mounted() {
    UserStore.getMe();
    API.isLoggedIn().then((r) => {
      if (!r) this.$router.push("/");
    });
    setInterval(() => {
      API.getMyProfile().then((me) => {
        this.me = me;
      });
    }, 60000);
    API.getMe().then((me) => {
      this.me = me;
      this.initialLoad = true;
      if (me._id) {
        askNotificationPermission();
      }
    });
    if (!this.isMobile) {
      (function () { window.$subsplashMessaging = { appKey: 'G2XZK8', brandColor: '#09016b', }; var s1 = document.createElement('script'), s0 = document.getElementsByTagName('script')[0]; s1.async = true; s1.src = 'https://js.static.subsplash.com/prod/web-embeds/web-messaging/bundle.js'; s1.charset = 'UTF-8'; s0.parentNode.insertBefore(s1, s0); })();
    }
  },
  watch: {
    $route() {
      API.getMe().then((me) => {
        this.me = me;
      });
    },
  },
  methods: {
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },
  },
};
</script>

<style lang="scss">
body {
  //background: #edf2f7;
  //background-image: url("./assets/LogoNoTextHighRes.png");
  background-blend-mode: screen;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.card {
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.main-wrapper {
  margin: 15px 10px 0 auto;
  max-width: 700px;
  padding-bottom: 70px;
  padding-top: 0;
  margin-top: 0;
  @media (min-width: 1200px) {
    margin: 15px auto 0;
  }
}

.material-icons {
  vertical-align: middle;
}

#nav {
  position: fixed;
  padding: 60px;
  max-width: 400px;
  font-size: 1.3em;

  a {
    display: block;
    font-weight: 500;
    color: #2c3e50;
    text-decoration: none;
    padding-bottom: min(15vw, 13px);

    &.router-link-exact-active {
      color: #42b983;
    }
  }

  .currentUser .material-icons {
    margin: 1px;
    margin-right: 7px;
  }

  @media (max-width: 900px) {
    background-color: white;
    height: 100vh;
    z-index: 100;
    top: 0;
    left: 0%;
    font-size: 1.2rem;
    transform: translate(-100%, 0);
    transition: transform cubic-bezier(0.165, 0.84, 0.44, 1) 0.7s 0.3s;
    max-width: 250px;

    a {
      opacity: 0;
      transform: translate(0, -10px);
      transition: all ease-in-out 0.4s;
    }

    &.active {
      transform: translate(0, 0);
      transition: transform cubic-bezier(0.165, 0.84, 0.44, 1) 0.7s;

      a {
        opacity: 1;
        transform: translate(0, 0);
        transition: all ease-in-out 0.5s 0.4s;
      }
    }

    h4 {
      font-size: 2rem;
    }
  }
}

.mobile-menu-icon {
  display: none;

  @media (max-width: 900px) {
    display: block;
    position: fixed;
    left: 10px;
    top: 10px;
    z-index: 110;
    width: 25px;

    &.active {}
  }
}

#rightPanel {
  position: fixed;
  right: 10px;
  top: 10px;
  padding: 30px;

  @media (max-width: 900px) {
    display: none;
  }
}

.form-floating>label {
  left: 5px;
}

.pointer {
  cursor: pointer;
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: translate(0, -10px);
}

.profile-img {
  object-fit: cover;
  width: 200px;
  height: 200px;
}

.profile-info .profile-img {
  width: 50px;
  height: 50px;
  position: absolute;
  transform: translate(0, -8px);
  left: 10px;
}

.profile-info .profile-img+.description {
  padding-left: 60px;
}
</style>
